<template>
  <div class="yingshou"></div>
</template>

<script>
export default {
  name: 'Yingshou'
}
</script>

<style scoped>
.yingshou {
  height: 380px;
  width: 100%;
  background: url(~assets/img/scheme/yingshou.png);
  background-size: 100% 100%;
}
</style>
