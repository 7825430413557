<template>
  <div class="scheme">
    <banner />
    <service />
    <tongdao />
    <yingshou />
    <jingying />
    <dayy />
    <youshiuse />
  </div>
</template>

<script>
import Banner from './subpage/Banner'
import Service from './subpage/Service'
import Tongdao from './subpage/Tongdao'
import Yingshou from './subpage/Yingshou'
import Jingying from './subpage/Jingying'
import Dayy from './subpage/Dayy'
import Youshiuse from './subpage/Youshiuse'

export default {
  name: 'Scheme',
  components: {
    Banner,
    Service,
    Tongdao,
    Yingshou,
    Jingying,
    Dayy,
    Youshiuse
  }
}
</script>

<style scoped>
.scheme {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}
</style>
