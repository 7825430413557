<template>
  <div class="jingying">
    <div class="title">中医馆全流程SaaS服务</div>
    <p class="subtitle">实现中医馆“+互联网”，打造互联网中医馆</p>
    <div class="clearfix" style="margin-top: 50px">
      <div class="line">
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #eb6100; color: #eb6100"
          >
            <img src="~assets/img/scheme/icon6.png" alt="" />
            <p>特色官网</p>
          </div>
          <p>挖掘医馆特色，构建智能化宣传库，答疑库，商品库。</p>
        </div>
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #90c321; color: #90c321"
          >
            <img src="~assets/img/scheme/icon7.png" alt="" />
            <p>公众号助手</p>
          </div>
          <p>微信服务号同步，快速吸粉。</p>
        </div>
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #00b7ee; color: #00b7ee"
          >
            <img src="~assets/img/scheme/icon8.png" alt="" />
            <p>渠道营销</p>
          </div>
          <p>线上多渠道营销，次卡销售，活动营销。</p>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #c15d3e; color: #c15d3e"
          >
            <img src="~assets/img/scheme/icon9.png" alt="" />
            <p>预约挂号</p>
          </div>
          <p>微信挂号，支付确认，多渠道营销，统一号源，一体机自助取号。</p>
        </div>
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #708dda; color: #708dda"
          >
            <img src="~assets/img/scheme/icon10.png" alt="" />
            <p>诊断开方</p>
          </div>
          <p>处方库，治疗方案，诊断模板库，智能辅助诊断，四诊仪设备对接。</p>
        </div>
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #85bc61; color: #85bc61"
          >
            <img src="~assets/img/scheme/icon11.png" alt="" />
            <p>电子病历</p>
          </div>
          <p>联动生成医案，医案生产价值。</p>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #58c28c; color: #58c28c"
          >
            <img src="~assets/img/scheme/icon12.png" alt="" />
            <p>前台收银</p>
          </div>
          <p>开单，会员，次卡，活动，收银，煎药，ERP，对账，业绩全联动。</p>
        </div>
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #f39a04; color: #f39a04"
          >
            <img src="~assets/img/scheme/icon13.png" alt="" />
            <p>线上运营</p>
          </div>
          <p>线上医案运营，健康产品运营，中医知识库运营。</p>
        </div>
        <div class="item">
          <div
            class="circle"
            @mouseenter="mouseEnterItem"
            @mouseleave="mouseLeaveItem"
            style="border: 2px solid #c15d3e; color: #c15d3e"
          >
            <img src="~assets/img/scheme/icon14.png" alt="" />
            <p>用户互动</p>
          </div>
          <p>患者答疑，患者反馈，患者沟通，医案联动。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Jingying',
  data() {
    return {}
  },
  methods: {
    mouseEnterItem(e) {
      // console.log(e.target.style)
      e.target.style.backgroundColor = '#FFFAF0'
    },
    mouseLeaveItem(e) {
      e.target.style.backgroundColor = ''
    }
  }
}
</script>

<style scoped>
.jingying {
  padding: 50px 0;
  background: #f5f5f5;
}

.title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 24px;
}

.subtitle {
  font-size: 20px;
  color: #656565;
  text-align: center;
  line-height: 32px;
}

.line {
  display: flex;
  justify-content: center;
}

.item {
  width: 320px;
  margin: 20px 40px;
  text-align: center;
}

.item > p {
  font-size: 16px;
  margin: 0 18px;
}

.circle {
  width: 106px;
  height: 106px;
  padding-top: 15px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 10px;
}

.circle p {
  padding-top: 5px;
  font-size: 14px;
}

.hover {
  background-color: #ffefd5;
}
</style>
