<template>
  <div class="youshiuse">
    <div class="youshi">
      <div class="title">我们的优势</div>
      <div class="clearfix">
        <div>
          <img src="~assets/img/scheme/icon_20.png" alt="" />
          <p class="subtitle">患者同道打造</p>
          <p class="content">
            建立患者与医馆之间的互联网通道，让患者成为医馆的口碑传播者，让医馆的特色能有效传播。
          </p>
        </div>
        <div>
          <img src="~assets/img/scheme/icon_19.png" alt="" />
          <p class="subtitle">服务体验打造</p>
          <p class="content">
            全自动中医诊疗流程，预约挂号、网上支付、取号候诊、就诊开方、结账收费、治疗实施、抓药煎药、反馈随访。全面提高用户诊疗体验。
          </p>
        </div>
        <div class="noborder">
          <img src="~assets/img/scheme/icon_21.png" alt="" />
          <p class="subtitle">诊疗效果打造</p>
          <p class="content">
            辩机论治智能辅助诊断、医师传帮带，让诊断疗效持续提高。
          </p>
        </div>
      </div>
    </div>
    <div class="use">
      <div class="title">他们都在用</div>
      <div class="clearfix-2">
        <div>
          <h3>方典经方中医馆</h3>
          <p>独立域名：http://fd.sktcm.com</p>
          <p>医馆地址：深圳福田红荔西路第一世界广场A座411</p>
          <a href="http://fd.sktcm.com" target="_blank" class="enter"
            >进入医馆</a
          >
        </div>
        <div>
          <h3>五味中医馆</h3>
          <p>独立域名：http://wwzyg.sktcm.com</p>
          <p>
            医馆地址：深圳市龙岗区坂田五和大道雅园路手造文化街国粹区B101-103
          </p>
          <a href="http://wwzyg.sktcm.com" target="_blank" class="enter"
            >进入医馆</a
          >
        </div>
        <div>
          <h3>黄现展中医诊所</h3>
          <p>独立域名：http://h.sktcm.com</p>
          <p>医馆地址：东莞市凤岗镇雁田凤凰山高尔夫球场5号铺</p>
          <a href="http://h.sktcm.com" target="_blank" class="enter"
            >进入医馆</a
          >
        </div>
        <div>
          <h3>观仁堂中医馆</h3>
          <p>独立域名：http://grt.sktcm.com</p>
          <p>医馆地址：深圳市南山区华侨城光华街19号（雅枫宾馆对面）</p>
          <a href="http://grt.sktcm.com" target="_blank" class="enter"
            >进入医馆</a
          >
        </div>
        <div>
          <h3>邢斌针药结合工作室</h3>
          <p>独立域名：http://zyjh.sktcm.com</p>
          <p>
            医馆地址：上海市黄浦区斜徐路553号（近丽园路）(泰坤堂中医医院，上海市医保定点单位）（工作室首家入驻医院）
          </p>
          <a href="http://zyjh.sktcm.com" target="_blank" class="enter"
            >进入医馆</a
          >
        </div>
        <div>
          <h3>董古医堂</h3>
          <p>独立域名：http://dgyt.sktcm.com</p>
          <p>医馆地址：深圳市宝安西乡流塘路28-6号（嘉华花园西区1栋105商铺）</p>
          <a href="http://dgyt.sktcm.com" target="_blank" class="enter"
            >进入医馆</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Youshiuse'
}
</script>

<style scoped>
.youshiuse {
  /* height: 1494px; */
  background: url(~assets/img/scheme/index-scheme-bg.png) top center no-repeat;
  padding: 80px 0;
}

.title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 80px;
}

.clearfix {
  display: flex;
  justify-content: center;
}

.clearfix > div {
  width: 425px;
  padding: 20px 0;
  border-right: 1px solid #dedede;
  text-align: center;
}

.clearfix .subtitle {
  font-size: 24px;
  color: #c15d3e;
  line-height: 40px;
  padding-top: 10px;
}

.clearfix .content {
  color: #656565;
  font-size: 16px;
  line-height: 28px;
  margin: 20px auto 0;
  width: 173px;
}

.clearfix .noborder {
  border-right: none;
}

.use {
  /* width: 1280px; */
  /* min-width: 1220px; */
  margin: 100px auto 0;
  padding: 70px 0 50px;
}

.clearfix-2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 100px;
}

.clearfix-2 > div {
  width: 400px;
  height: 200px;
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 15px;
  margin: 15px;
  box-shadow: 0 0 10px rgba(200, 200, 200, 0.4);
  text-align: left;
}

.clearfix-2 h3 {
  font-size: 22px;
  font-weight: normal;
  padding: 5px 0 5px 35px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  background: url(~assets/img/scheme/icon18.png) no-repeat left center;
}

.clearfix-2 p {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.clearfix-2 a {
  display: inline-block;
  width: 118px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 15px;
  color: #c15d3e;
  background: url(~assets/img/scheme/arrow.png) no-repeat 95px center;
}
</style>
